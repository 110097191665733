<template>
  <div>
    <div v-show="!loading">
      <v-virtual-scroll
        :items="items"
        :height="height"
        :itemHeight="itemHeight"
        :bench="bench"
      >
        <template v-slot:default="{ item }">
          <slot :item="item">
            <v-list-item
              @click="handleClickListItem(item)"
              link
              :class="getItemClass(item)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ item[itemText] }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox
                  :input-value="itemActive(item)"
                  :color="`${color} accent-4`"
                ></v-checkbox>
              </v-list-item-action>
            </v-list-item>
          </slot>
        </template>
      </v-virtual-scroll>
    </div>
    <v-list v-show="loading">
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
    </v-list>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "text",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    value: {},
    color: {
      type: String,
      default: "primary",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "200",
    },
    itemHeight: {
      type: String,
      default: "50",
    },
    bench: {
      type: Number,
      default: 5,
    },
  },

  methods: {
    handleClickListItem(item) {
      if (this.itemActive(item)) {
        this.removeItem(item);
        return;
      }

      this.addItem(item);
    },
    setItems(items = []) {
      this.$emit("input", items);
    },
    addItem(item) {
      this.$emit("input", this.value.concat(item[this.itemValue]));
      this.$emit("itemAdded", item);
    },
    removeItem(item) {
      this.$emit(
        "input",
        this.value.filter((e) => e !== item[this.itemValue])
      );
      this.$emit("itemRemoved", item);
    },
    itemActive(item) {
      return this.value.some((e) => e === item[this.itemValue]);
    },
    getItemClass(item) {
      if (!this.itemActive(item)) return "";

      return `${this.color}--text`;
    },
    handleClickAll() {
      this.setItems(this.items.map((item) => item[this.itemValue]));
    },
    handleClickClear() {
      this.setItems([]);
    },
  },
};
</script>
