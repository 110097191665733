<template>
  <div>
    <v-list-item @click="handleEditUser">
      <v-icon left size="20">mdi-pencil</v-icon>
      <v-list-item-title>{{
        __('Alterar', 'unities')
      }}</v-list-item-title>
    </v-list-item>
    <v-dialog v-model="unity_dialog" max-width="500" persistent>
      <unity-form
        v-model="unity"
        :title="__('Dados da Unidade', 'unities')"
        @submit="handleSubmitForm"
        @close="handleCloseDialog"
      ></unity-form>
    </v-dialog>
  </div>
</template>

<script>
import i18n from "@/mixins/i18n";
import { groupBy } from "@/tools/array";
import { 
  show as showUnity,
  index as indexUnities
} from "@/services/unities"
import UnityForm from "@/components/forms/UnityForm";
export default {
  components: { UnityForm },
  props: {
    value: {
      type: Object,
      default: null
    },
  },
  mixins: [i18n],
  data() {
    return {
      items: [],
      loading: false,
      unity_dialog: false,
      unity: {},
      options: {
        itemsPerPage: 10,
        sortBy: ["name"],
        sortDesc: [],
      },
      filter: "",
      advanced_filters: {
        admin_rating: [],
        is_active: [],
        scheduling_qty_operator: ">",
        service_types: [],
      },
      advanced_filtered: false,
    };
  },
  methods: {
    handleSubmitForm(event) {
      this.getUnities();
    },
    handleCloseDialog() {
      this.unity_dialog = false;
    },
    checkAdvancedFilterInParams(params) {
      return (
        !!params.name ||
        !!params.email ||
        params.admin_rating.length ||
        params.is_active.length ||
        !isNaN(params.scheduling_qty) ||
        this.advanced_filters.service_types.length
      );
    },
    async getUnities() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.loading = true;
      this.items = [];

      try {
        const params = {
          paginate: true,
          orderBy: sortBy[0] ? sortBy[0] : "",
          sortDesc: sortDesc[0] ? sortBy[0] : "",
          page,
          perPage: itemsPerPage,
          filter: this.filter || "",
          name: this.advanced_filters.name,
          email: this.advanced_filters.email,
          admin_rating: this.advanced_filters.admin_rating,
          is_active: this.advanced_filters.is_active,
          scheduling_qty_operator:
            this.advanced_filters.scheduling_qty_operator,
          service_types: this.advanced_filters.service_types,
        };

        const data = await indexUnities(params);

        this.items = data.data;
        this.totalItems = data.total;
        this.advanced_filtered = this.checkAdvancedFilterInParams(params);
      } catch (error) {
        this.items = [];
        this.totalItems = 0;
      } finally {
        this.loading = false;
      }
    },
    async handleEditUser() {
      const response = await showUnity({}, this.$route.params.id);
      const unityData = response.data;
      this.loading = true;
      try {
        const unityRecord = await showUnity({}, unityData.id);
        this.unity = {
          id: unityData.id,
          user: unityData.user_id,
          user_name: unityData.name,
          team_id: unityData.team_id,
          service_types: unityData.service_types.map(
            (service_type) => service_type.id
          ),
          actuation_zones: groupBy(
            unityRecord.data.actuation_zones,
            (zone) => zone.country_area_id
          ).map(([country_area_id, zones]) => ({
            id: country_area_id,
            cities: zones.map((city) => city.id),
          })),
        }
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: __('Falha ao carregar unidade', 'unities'),
          color: "red",
        });
      } finally {
        this.loading = false;
        this.unity_dialog = true;
      }
    },
    created() {
      this.getUnities();
    },
  },
};
</script>
