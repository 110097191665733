<template>
  <v-dialog v-model="dialog" width="450" persistent>
    <v-card v-if="form">
      <v-card-title> {{ form.unity_service_type.description }} </v-card-title>

      <v-card-text class="mt-3">
        <SelectMultipleCountryAreas v-model="form.countryAreas" />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="handleCancel" text color="primary"> {{ __('Cancelar', 'unities') }} </v-btn>
        <v-btn @click="handleSubmit()" color="primary"> {{ __('Salvar', 'unities') }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SelectMultipleCountryAreas from "@/components/locals/SelectMultipleCountryAreas";
import i18n from '@/mixins/i18n';

export default {
  components: {
    SelectMultipleCountryAreas,
  },
  mixins: [i18n],
  data() {
    return {
      dialog: false,
      form: null,
    };
  },

  methods: {
    init(initialForm) {
      this.form = { ...initialForm };
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    handleCancel() {
      this.dialog = false;
    },

    handleSubmit() {
      this.$emit("submit", this.form);
    },
  },
};
</script>
