<template>
  <div>
    <v-list-item
      @click="changeUserActive(unity)"
      v-if="unity.is_active"
    >
      <v-icon color="red" left size="20">mdi-lock</v-icon>
      <v-list-item-title class="red--text">{{
        __('Bloquear', 'unities')
      }}</v-list-item-title>
    </v-list-item>
    <v-list-item @click="changeUserActive(unity)" v-else>
      <v-icon left size="20" color="red">mdi-lock-open</v-icon>
      <v-list-item-title class="red--text">{{
        __('Desbloquear', 'unities')
      }}</v-list-item-title>
    </v-list-item>
  </div>
</template>

<script>
import i18n from "@/mixins/i18n";
import { updateStatus } from "@/services/users";
export default {
  props: {
    unity: {
      type: Object,
      default: null
    },
  },
  mixins: [i18n],
  data() {
    return {
      items: [],
    };
  },
  methods: {
    async changeUserActive(unity) {
      const status = !unity.is_active;

      //Feedback instantâneo
      unity.is_active = status;

      try {
        await updateStatus(unity.user_id, status);
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: "Não foi possível mudar status da unidade",
          color: "red",
        });
        //Retorna o estado anterior
        this.items.map((item) => {
          if (item.id === unity.id) {
            item.is_active = !status;
          }

          return unity;
        });
      }
    },
  },
};
</script>
