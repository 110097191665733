<template>
  <div>
    <v-list-item value="true" @click="handleRemoveUnity(unity)">
      <v-icon color="red" left size="20">mdi-delete</v-icon>
      <v-list-item-title class="red--text">{{
        __('Remover', 'unities')
      }}</v-list-item-title>
    </v-list-item>
    <v-dialog v-model="confirm_dialog" max-width="300" persistent>
      <v-card>
        <v-card-title>
          {{ __('Remover unidade', 'unities') }}
          <v-spacer></v-spacer>
          <v-btn @click="confirm_dialog = false" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading_deleting_unity"
            text
            @click="confirm_dialog = false"
            >{{ __('Não', 'unities') }}
          </v-btn
          >
          <v-btn
            :loading="loading_deleting_unity"
            color="red"
            dark
            @click="removeUnity()"
            >{{ __('Remover', 'unities') }}
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18n from "@/mixins/i18n";
import {
  destroy as deleteUnity 
} from "@/services/unities";
export default {
  props: {
    unity: {
      type: Object,
      default: null
    }
  },
  mixins: [i18n],
  data() {
    return {
      items: [],
      confirm_dialog: false,
      confirm_unity: null,
      loading_deleting_unity: false,
    };
  },
  methods: {
    handleRemoveUnity(unity) {
      this.confirm_dialog = true;
      this.confirm_unity = unity;
    },
    async removeUnity() {
      this.loading_deleting_unity = true;
      try {
        await deleteUnity(this.confirm_unity.id);
        this.items = this.items.filter(
          (item) => item.id !== this.confirm_unity.id
        );
        this.$store.commit("sendMessage", {
          text: "Unidade excluída com sucesso",
        });
        this.confirm_dialog = false;
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: "Não foi possível excluír a unidade",
          color: "red",
        });
      } finally {
        this.loading_deleting_unity = false;
      }
    },
  },
};
</script>
