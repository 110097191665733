<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card v-if="district">
      <v-card-title> {{ district.name }} </v-card-title>
      <v-card-text>
        <v-chip @click="handleClickAll()" small> Marcar tudo </v-chip>
        <v-chip @click="handleClickClear()" small class="ml-1">
          Desmarcar tudo
        </v-chip>
      </v-card-text>
      <v-divider></v-divider>
      <list-select
        v-model="selectedCities"
        :items="cities"
        :loading="loadingCities"
        item-text="name"
        item-value="id"
        item-height="50"
        height="400"
        :bench="2"
      />
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="handleClickCancel" text> Cancelar </v-btn>
        <v-btn @click="handleClickSave" color="primary"> Salvar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getCities } from "@/services/locals";
import ListSelect from "@/components/ListSelect";

export default {
  components: {
    ListSelect,
  },

  data() {
    return {
      dialog: false,
      district: null,
      service_type_id: null,
      cities: [],
      selectedCities: [],
      loadingCities: false,
    };
  },

  methods: {
    init(district, service_type_id, selectedCities = []) {
      this.dialog = true;
      this.selectedCities = selectedCities;
      this.district = district;
      this.service_type_id = service_type_id;
      this.loadCities(district);
    },

    handleClickAll() {
      this.selectedCities = this.cities.map((city) => city.id);
    },

    handleClickClear() {
      this.selectedCities = [];
    },

    async loadCities(district) {
      this.cities = [];
      this.loadingCities = true;
      try {
        this.cities = await getCities(district.id);
      } catch (error) {
        throw error;
      } finally {
        this.loadingCities = false;
      }
    },

    handleClickSave() {
      this.$emit("submit", {
        selected_cities: this.selectedCities,
        district: this.district,
        service_type_id: this.service_type_id,
      });
      this.dialog = false;
    },

    handleClickCancel() {
      this.dialog = false;
    },
  },
};
</script>
