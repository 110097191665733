<template>
  <div>
    <v-text-field
      v-model="search"
      label="Buscar"
      append-icon="mdi-magnify"
      single-line
    ></v-text-field>
    <v-list style="max-height: 25rem; overflow-y: auto" shaped>
      <v-list-item
        @click="handleCheckAll"
        :input-value="allChecked"
        active-class="primary--text text-accent-4"
        v-if="search === ''"
      >
        <template v-slot:default="{ active }">
          <v-list-item-content>
            <v-list-item-title>Tudo</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-checkbox
              readonly
              :input-value="allChecked"
              color="primary accent-4"
            ></v-checkbox>
          </v-list-item-action>
        </template>
      </v-list-item>
      <v-list-item-group :value="value" multiple>
        <template v-for="(countryArea, i) in filteredCountryAreas">
          <v-divider :key="`divider-${i}`"></v-divider>
          <v-list-item
            :key="`item-${countryArea.id}`"
            active-class="primary--text text-accent-4"
            @click="handleClickItem(countryArea)"
            :value="countryArea.id"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title v-text="countryArea.name" />
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  color="primary accent-4"
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { getCountryAreas } from "@/services/locals";
import { search } from "@/tools/array.js";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      search: "",
      countryAreas: [],
      loading: false,
    };
  },

  methods: {
    handleCheckAll() {
      if (this.allChecked) {
        this.input([]);
        return;
      }

      this.input(this.countryAreas.map((ca) => ca.id));
    },
    handleClickItem(countryArea) {
      if (this.itemChecked(countryArea)) {
        this.removeCountryArea(countryArea);
        return;
      }

      this.addCountryArea(countryArea);
      return;
    },
    itemChecked(countryArea) {
      return this.value.some((e) => e === countryArea.id);
    },
    removeCountryArea(countryArea) {
      this.input(this.value.filter((item) => item !== countryArea.id));
    },
    addCountryArea(countryArea) {
      this.input(this.value.concat(countryArea.id));
    },
    input(value) {
      this.$emit("input", value);
    },
    async loadCountryAreas() {
      this.loading = true;
      this.countryAreas = await getCountryAreas("pt");
      this.loading = false;
    },
  },

  computed: {
    allChecked() {
      return this.countryAreas.every((e) => this.value.includes(e.id));
    },
    filteredCountryAreas() {
      return search(this.search, this.countryAreas, (item) => item.name);
    },
  },

  mounted() {
    this.loadCountryAreas();
  },
};
</script>
